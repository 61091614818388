.ComoFunciona-container,
.como-funciona-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 400px;
    max-width: 1320px;
    padding: 40px 40px;
    margin: auto;

}
.como-funciona-content div{
    flex: 1;
}

.como-funciona-content h1{
    text-align: center;
    color: #1d1d1b;
    font-size: 30px;
    line-height: 1.3;
    letter-spacing: 1.5px;
    font-size: 38px;
    margin-bottom: 10px;
}
h1.main-title-como{
    font-size: 30px;
}




@media (max-width: 768px) {
    .main-title-como {
      /* Adicione estilos específicos para o título em dispositivos móveis */
    }
  
   .como-funciona-content img.mobile-image {
      display: block; /* Torna a imagem visível em dispositivos menores que 768px */
    }

  }
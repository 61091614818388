.header-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  background-color: #D5D2C9;
  z-index: 101;
  width: 100%;
  height: 90px;
  box-shadow: 0 1px 1px 0 #8571643d;
}

.header-container{
    padding: 10px 40px;
    color: rgba(255, 255, 255, 0.93);
    display: flex;
    align-items: center;
    z-index: 100;
    max-width: 1250px;
    margin: auto;
    height: 100%;
    gap: 40px;
    justify-content: space-between;
}

.header-btns-container{
  display: flex;
  gap: 20px;
  align-items: center;
}

.header-container .logo a{
  color: rgba(255, 255, 255, 0.93);
}

.menu-toggle{
  background-color: transparent;
}

.menu-toggle img{
  max-width: 40px !important;
}

.close-menu-btn{
  position: absolute;
  top: 20px;
  right: 20px;
}

.logo-dani{
  margin-top: 7px;
  margin-left: -3px;
  max-width: 200px;
}

.menu-desktop{
  display: flex;
  gap: 20px;
}

@media (min-width: 769px) {
  .menu-toggle{
    display: none;
  }
}

@media (max-width: 768px) {
  .header-container{
    padding: 10px 20px;
  }

  .menu-desktop{
    display: none;
  }

  .menu-toggle{
    display: block;
  }

}
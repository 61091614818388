.QuemSouEU-container{
    min-height: 400px;
    max-width: 1320px;
    padding: 60px 40px;
    margin: auto;
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    gap: 40px;
}
.dani-name{
    font-size: 32px;
    font-family:"Prata", Arial;
}
.dani-img img{
    width: 350px;

}
.main-titles{
    gap: 20px;

}
.quem-texts{
    display: flex;
    flex-direction: column;
    gap: 30px;
}


@media screen and (max-width: 768px) {

    .QuemSouEU-container{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .dani-img img{
        width: 300px;
    }
    
    
  }
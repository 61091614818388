.sidebar {
  position: absolute;
  top: 0;
  right: 0;
  width: 300px;
  height: 100vh;
  padding: 40px;
  background-color: #cbcbcb;
  z-index: 100;
  z-index: 1000 !important;
}

.menu-toggle img {
  width: 100%;
  height: auto;
  max-width: 40px !important;
}

.close-menu-btn {
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 30px;
  background-color: transparent;
  color: #666666;
}

.close-menu-btn img {
  max-width: 30px !important;
}

.sidebar a,
.sidebar button {
  display: block;
  height: 46px;
  line-height: 46px;
  cursor: pointer;
  color: #1d1d1b !important;
  background-color: transparent;
  font-size: 22px;
  text-transform: none !important;
  font-weight: 500;
}

.curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

.sidebar-socials {
  position: absolute;
  bottom: 50px;
  left: 50px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.sidebar-socials-content {
  display: flex;
  gap: 5px;
}

.sidebar-socials-content a {
  height: fit-content;
  line-height: 1;
  
  font-size: 22px;
}

.menu-btn-scroll {
  font-size: 14px;
  color: #1d1d1b;
  ;
  font-family: "Muli", "Helvetica Neue";
  position: relative;
  
  text-decoration: none;
  transition: color 0.5s ease, border-bottom-color 0.3s ease;
  background-color: transparent;

  border-bottom: 1px solid transparent;
}
button.menu-btn-scroll {
  font-size: 20px;
}
.menu-btn-scroll:hover {
  color: #857164;
  border-bottom: 1px solid #1D1D1B;
}


@media (max-width: 768px) {

button.menu-btn-scroll {
  font-size: 16px;
}
}
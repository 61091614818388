.footer__container {
  background-color: #B48F6D;

}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding: 30px 20px;
  color: rgba(255, 255, 255, 0.936);
  flex-wrap: wrap;
  max-width: 1250px;
  margin: auto;
}

.footer h3 {
  margin-bottom: 10px;
  border-bottom: solid 1px rgba(255, 255, 255, 0.936);
  padding-bottom: 5px;
  max-width: 70px;
  font-size: 18px;
}

.footer a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 16px;
}

.footer-icons {
  margin-top: 40px;
  font-size: 40px;
}

.footer-icons i {
  background-color: rgb(208, 208, 208);
  color: #000000;
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  width: 50px;
  height: 50px;
  padding: 5px;
}

.footer p {
  font-size: 16px;
  line-height: 1.3;
  width: 600px;
}

.footer-icons-content {
  display: flex;
  gap: 10px;
}

.copy__container {
  background-color: #D5D2C9;
}

.copy {
  max-width: 1150px;
  margin: auto;
  color: #1D1D1B;
  padding: 10px;
  font: 14px;
}

.copy a {
  text-decoration: underline;
  color: #1D1D1B;
}


.link-footer{
  display: flex;
  flex-direction: column;
  
}
.footer__menu a {
  border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  padding-bottom: 5px;
}

.footer a:hover {
  text-decoration: underline;
}

.luana-name{
  font-size: 16px;
}
@media (min-width: 768px) {
  .footer .footer__child {
    flex: 0 0 30.3%;
    max-width: 30.3%;
  }
}

@media (max-width: 768px) {
  .footer {
    flex-direction: column;
    gap: 30px;
  }

  .footer__menu {
    gap: 10px;
  }
  .footer p {
    font-size: 16px;
    line-height: 1.3;
    width: 300px;
  }
}


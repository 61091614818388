.visibilidade{
  background-color: #AA7049;
}
.gray{
  background-color: #857164;
}
p.explicacao{
  color: #d5d2c9;
  font-weight: 550;
}
.main-title-como{
  padding: 40px 20px;
  text-align: center;
}
.titulo-e-img{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.titulo-e-img-visibilidade{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titulo-e-img-visibilidade h3{
  color: #d5d2c9;
}
.titulo-e-img h3{
    color: #d5d2c9;
}
.titulo-e-img-visibilidade img{
  max-width: 60px;
}
.motivos{
    margin:40px;
}
  .titulo-e-img img{
    max-width: 60px;
  }
  img.estrategias{
    margin-right: -10px;
  }
  .title-sessao{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
.porque-precisa-content ul div {
    margin-top: 20px;
    padding-bottom: 10px;
    display: flex;
    gap: 30px;
    justify-content: center;
    text-align: center;
    font-size: 18px;
}
  @media (max-width: 768px) {
    .porque-precisa-content ul div {
      flex-direction: column;
    }
  }

  .porque-precisa-content ul div li {
    flex: 1;
    max-width: 450px;
    padding: 8px 20px;
    border-top-right-radius:40px;
    border-bottom-left-radius:40px;
    box-shadow: 0 14px 28px #00000001, 0 10px 10px #00000020;
    border: 1px solid #B48F6D;
    backdrop-filter: blur(12px);
    line-height: 1.3;
    z-index: 10;
  }
  
  .porque-precisa-content strong {
    display: block;
    margin-bottom: 10px;
    font-size: 22px;
  }
  
  .shape-small-grid {
    position: absolute;
    bottom: -2rem;
    right: 2rem;
    width: 200px;
    height: 180px;
    border: 50%;
    background-color: #3254fc;
    filter: blur(90px);
  }
  h1.main-title-como{
    font-size: 30px;
}
.Sobre-container {
  display: flex;
  justify-content: center;
  align-items: center;

}
.logo-insta {
  max-width: 50px;
  color: #aa7049;
}
.nomeinsta {
  color: #aa7049;
}


.textos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding:50px 30px ;

  gap: 40px;
  
}
h1.titulo{
  text-align: center;
  font-size: 32px;
}

.insta {
  display: flex;
  align-items: center;
  gap: 6px;
}
@media (max-width: 780px) {
  .logo-insta {
    max-width: 30px;
    color: #aa7049;
  }

  .h1especial {
    font-size: 25px;
    line-height: 1.3;
    letter-spacing: 1.5px;
    margin-bottom: 10px;
  }

  img.daniultima {
    line-height: 0;
  }
}

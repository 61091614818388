.faq-container {

  
  padding: 20px 20px;
  color: #857164;
  max-width: 750px;
  margin: auto;
}

.faq-item {
  padding: 15px 0;
  margin-bottom: 20px;
  border-bottom: 1px solid #857164;
}

.faq-question button{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  color: #AA7049;
}
.faq-h1{
  color:#1d1d1b;
  text-align: center;
  margin-bottom: 20px;
}
.faq-question span{
  background-color: #B48F6D;
  color: #857164;
  font-size: 20px;
  padding: 2px 8px;
  border-radius: 5px;
}

.faq-item h3 {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.03em;
  margin-top: 0;
  line-height: 1.3;
  text-align: left;
}

.faq-item p {
  margin-top: 10px;
  color: #857164;
  margin-bottom: 0;
  font-size: 18px;
  line-height: 1.5;
}

@media (max-width: 450px) {
  .faq-item h3{
    font-size: 18px;
  }

  .faq-item p{
    font-size: 16px;
  }

}


.servicos{
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items:center;  
    justify-content: space-between;
    max-width: 350px;
    border: 1px solid #857164;
    padding: 20px;
    border-radius: 40px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 0px;
    text-align: center;
    box-shadow: 0 14px 28px #00000001, 0 10px 10px #00000020;
    
}
.servicos-conteudo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    gap: 10px;
}
div.titulos-cards{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
p.subtitulos-cards{
    font-size: 16px;
    text-align: center;
    margin-left: 7px;
 
}
p.servicos {
    font-size: 16px;
    text-align: left ;
    color: #464646;
    
}
.span-diferente{
    font-size: 18px;
}
p.p-diferente{
    font-size: 16px;
    text-align: left ;
    color: #464646;

}
.titulos{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
}
.valores{
    text-decoration: underline;
}
.servicos-card{
    display: flex;
    flex-direction: column;
    gap: 25px;
    align-items: center;
    border-radius: 40px;
    max-width: 1350px;
    margin: auto;
}
.arredondar{
    border-top-right-radius:40px;
    border-bottom-left-radius:40px;
}
.servicos-card h1{
    font-size: 34px;
    padding: 30px 0 30px 0;
    text-align: center;
}

.servicos-card h3{
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
}
.servicos-names{
    text-align: center;
    font-size: 28px;
    font-family:'Prata',;

}
.servicos-card ul{
    display: flex;
    gap: 35px;
    padding: 0 20px;
    flex-wrap: wrap;
    justify-content: center;
}
.sessao{
    display: flex;
    flex-direction: row;
    gap: 30px;
    align-items: center;
    max-width: 1350px;
    margin: auto;
    padding-bottom: 90px;

}

h3.titulo-servicos{
    line-height: 1.3;
    letter-spacing: 1.5px;
    font-size: 24px;
    margin-bottom: 10px;
}
span.valores2 {
    text-decoration: underline;

}
@media (max-width: 810px){
    .servicos-card ul{
        gap: 20px;
    }
    .servicos-card ul li{
        width: 100%;
        max-width: 500px;
    }
    .servicos p{
        font-size: 16px;
        text-align: left ;
        color: #464646;
    }
        
}
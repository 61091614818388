.App {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

@font-face {
  font-family: "Muli";
  src: url("../src/assets/font/muli/muli/Muli-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Prata";
  src: url("../src/assets/font/prata/Prata/Prata-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

.body-wrapper {
  margin-top: 90px;
  min-height: 80vh;
  background-color: #d5d2c9;
}

.body-container {
  max-width: 1300px;
  margin: auto;
  color: aliceblue;
}
i{
  font-family: "Muli";
  src: url("../src/assets/font/muli/muli/Muli-Italic.ttf") 

}
span,
p {
  font-family: "Muli", "Helvetica Neue";
  font-size: 20px;
  line-height: 1.3;
}
h3,
h1 {
  font-family: "Prata", Arial;
  font-weight: 500;
}
h2 {
  font-family: "Prata", Arial;
  font-weight: 500;
}

.main-btn {
  background-color: #b48f6d;
  border-radius: 20px;
  text-align: center;
  padding: 15px 40px;
  width: fit-content;
  color: #1D1D1B;
  font-size: 18px;
  letter-spacing: 1px;
  font-family: "Prata", "Helvetica Neue";
  transition: 0.3s;
}
.main-btn2 {
  background-color: #b48f6d;
  border-radius: 20px;
  text-align: center;
  padding: 15px 30px;
  width: fit-content;
  color: #1D1D1B;
  font-size: 16px;
  letter-spacing: 1px;
  font-family: "Prata", "Helvetica Neue";
  transition: 0.3s;
}

.main-btn:hover {
  background-color: #AA7049;
}
.main-btn2:hover {
  background-color: #AA7049;
}



.whats-btn a {
  z-index: 999;
  opacity: 1;
  z-index: 50;
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  padding: 10px;
  border-radius: 99px;
  transition: 0.4s;
  font-size: 50px;
  background-color: #B48F6D;
  color: #1D1D1B;
  
}

.whats-btn a:hover {
  color: #B48F6D;
  background-color: #1D1D1B;
  
}


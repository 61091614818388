h3 {
  font-family: "Muli", "Helvetica Neue";
  src: url("../../assets/font/muli/muli/Muli-Regular.ttf") format("truetype");
}

p {
  font-family: "Muli", "Helvetica Neue";
  src: url("../../assets/font/prata/Prata/Prata-Regular.ttf") format("truetype");
}
.home-container h1,
.home-container p,
.home-container h3 {
  color: #1d1d1b;
}
.home-container .nav-a :hover {
  background-color: #b48f6d;
}

.home-container {
  min-height: 400px;
  max-width: 1320px;
  padding: 80px 40px;
  margin: auto;
  display: flex;
  align-items: stretch;
  justify-content: center;
  position: relative;
  gap: 40px;
}
.home-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.home-container .img-container{
    max-width: 570px;
}


.home-container div {
  flex: 1;
}

.home-container h1{
    line-height: 1.3;
    letter-spacing: 1.5px;
    font-size: 38px;
    margin-bottom: 10px;
}

.home-container p{
    margin-bottom: 20px;
    font-size: 22px;
}

@media (max-width: 768px) {

  .home-container {
    flex-direction: column;
    padding: 60px 20px;
    padding-bottom: 0px;
  }
}
